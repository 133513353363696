<template>
  <header class="asf-checkout-header">
    <AsfWrapper type="header">
      <div :class="['asf-checkout-header__content', { 'm-mobile': isSmallView }]">
        <AsfSecureIcon title="Secure Checkout" class="asf-checkout-header__left" />

        <div class="asf-checkout-header__center">
          <AsfLogo v-if="logo && logo.src" :link="link" class="asf-checkout-header__link">
            <AsfImage :src="logo.src" :width="logo.width" :title="logo.alt" :alt="logo.alt" />
          </AsfLogo>

          <AsfLogo v-else class="asf-checkout-header__logo" :title="title" :link="link" />
        </div>

        <AsfLink
          v-show="!isSmallView"
          :link="`tel:${phoneNumber}`"
          :aria-label="$t('checkoutHeader.phoneTitle')"
          :title="$t('checkoutHeader.phoneTitle')"
          class="asf-checkout-header__right"
        >
          <AsfIcon name="phone" />
          <span>{{ phoneNumber }}</span>
        </AsfLink>
      </div>
    </AsfWrapper>
  </header>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AsfCheckoutHeaderProps, MediaMaxBreakpoints } from '@ui/types'

export default defineComponent({
  name: 'AsfCheckoutHeader',
  props: {
    /**
     * Defines logo
     */
    logo: { type: Object as PropType<AsfCheckoutHeaderProps['logo']>, default: () => ({}) },
    /**
     * Defines link for logo
     */
    link: { type: [String, Object] as PropType<AsfCheckoutHeaderProps['link']>, default: '/' },
    /**
     * Defines title for logo
     */
    title: { type: String as PropType<AsfCheckoutHeaderProps['title']>, default: '' },
    /**
     * Defines phone number
     */
    phoneNumber: { type: String as PropType<AsfCheckoutHeaderProps['phoneNumber']>, default: '' }
  },
  setup() {
    const { isMediaMatch: isSmallView } = useMediaQuery(MediaMaxBreakpoints.SM)

    return { isSmallView }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/CheckoutHeader/CheckoutHeader.css';
</style>
